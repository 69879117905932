<template>
	<div class="gongnue">			
			<div class="main">
				<TOP title="葫芦娃2" :isSearch="true" history="/gongnue"></TOP>	
				<TIELIST from="gongnue" history="/gongnue"></TIELIST>
				<!--发布按钮-->
				<PUBLISHBTN from="/gongnue"></PUBLISHBTN>
				<!--底部菜单-->
				<MENUE current="gongnue"></MENUE>
			</div>
	</div>
</template>

<script>
	import {
		defineComponent
	} from 'vue';
	import TOP from '@/components/top/top.vue';
	import TIELIST from '@/components/tie/list.vue';
	import MENUE from '@/components/menue/menue.vue';
	import PUBLISHBTN from '@/components/publish/pushlistBtn.vue';
	export default defineComponent({
		name: 'Home',
		components: {
			TOP: TOP,
			TIELIST: TIELIST,
			MENUE:MENUE,
			PUBLISHBTN
		},
		mounted() {
			document.querySelector("body").setAttribute("style",
				"background: linear-gradient(0deg, #EDEDD0, #DBEFE7);");
		},
		unmounted() {
			document.querySelector("body").setAttribute("style",
				"background: linear-gradient(#95C5B5, #64A4A4) no-repeat;");
		}
	});
</script>

<style lang="less" scoped="scoped">
	.main {
		padding: 0.25rem 0.2rem;
		position: fixed;
		top: 0;
		left: 0;
		right:0;
		bottom:0rem;
		overflow-y: auto;

		.kuangjieBtns {
			display: flex;
			margin-bottom: 0.24rem;
		}

		.kuangjieItem {
			width: 1.03rem;
			color: #5D6262;
			font-size: 0.25rem;
			display: flex;
			flex-direction: column;
			margin-right: 0.38rem;
			margin-left: 0.12rem;
			img {
				margin-bottom: 0.22rem;
			}
		}

		.gonggao {
			background: #F8FAF5;
			box-shadow: 0px 1px 2px 0px rgba(161, 161, 133, 0.36);
			border-radius: 0.14rem;
			padding: 0.33rem 0.23rem;
			font-size: 0.3rem;
			margin-bottom: 0.2rem;
			.gonggaoItem {
				margin-bottom: 0.15rem;
			}
		}
	}
</style>
<style lang="less">
	.gongnue{
		.van-dropdown-menu__bar{
			background: transparent;
			box-shadow:none;
			height: 0.5rem;
		}
	}
</style>
